export const environment = {
  production: true,
  name: 'gold',
  rh_env: 'staging',
  version: '#APP_VERSION#',
  ROLLBAR_ACCESS_TOKEN: '9c1c752e18404eeca7487f4c947fd2c3',
  ROLLBAR_CLIENT_ACCESS_TOKEN: 'a5e528e9d53b48a694c6a3666704215f',
  ROLLBAR_ENVIRONMENT: 'hub-gold',
  API_URL: 'https://hub-cms-api-1-gold.atpco.net',
  FLIGHT_SEARCH_API_URL: 'https://flights.retailing.atpco.net/fs-backend/v1',
  FLIGHT_SEARCH_UPA_URL: 'https://hub-cms-api-1-gold.atpco.net/api/upas',
  FLIGHT_SEARCH_CURL_URL: 'https://retailing.apis.atpco.net/routehappy/consolidated',
  GA_TRACKING_ID: 'G-6PCD7YMJY3',
  featureFlag: {
    flightSearch: false,
    findAFlight: true,
    countUpaByCategory: true,
    countUpaByStatus: true,
    countUpaReport: true,
    channelsFiltering: true,
    seatCharacteristics: {
      listingTable: true,
      targetingPage: true,
    },
    upaMetrics: false,
    gapAnalysisTool: true,
    changeHistory: true,
    accessControls: true,
    upaV2: false,
  }
};
